import React, { useState, useCallback, useEffect } from 'react';
import {
  Frame,
  Navigation,
  Spinner,
  AppProvider,
  TopBar,
} from '@shopify/polaris';
import { BrowserRouter, Link } from 'react-router-dom';
import enTranslations from '@shopify/polaris/locales/en.json';
import Routes from './routes';
import { authenticate, install } from './api';
import { QueryParams } from './utils';
import DataProvider from './context/DataContext';

function App(props: QueryParams) {
  const { code } = props;
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [token, setToken] = useState('');
  const [shop, setShop] = useState('');
  const [loading, setLoading] = useState(true);

  const auth = useCallback(async () => {
    try {
      const data = await authenticate(props);
      if (!data.authUrl) setLoading(false);
      setToken(data.token);
      setShop(props.shop);
    } catch (e) {
      console.error(e);
    }
  }, [props]);

  const initInstall = useCallback(async () => {
    try {
      await install(props);
      window.location.href = `https://${props.shop}/admin/apps`;
    } catch (e) {
      console.error(e);
    }
  }, [props]);

  useEffect(() => {
    if (code) {
      initInstall();
    } else if (!code) {
      auth();
    }
  }, [code, auth, initInstall]);

  const toggleMobileNavigationActive = () =>
    setMobileNavigationActive(!mobileNavigationActive);
  const navigation = (
    <>
      <Navigation location="/">
        <Navigation.Section
          items={[
            {
              selected: false,
              url: '/',
              label: 'Products',
            },
            {
              selected: false,
              url: '/settings',
              label: 'Settings',
            },
          ]}
        />
      </Navigation>
    </>
  );

  const topBar = (
    <TopBar
      showNavigationToggle
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  if (code || loading)
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner accessibilityLabel="Loading app" size="large" />
      </div>
    );
  return (
    <BrowserRouter>
      <DataProvider token={token} shop={shop}>
        <AppProvider
          i18n={enTranslations}
          linkComponent={(props) => (
            <Link className="Polaris-Navigation__Item" to={props.url || ''}>
              {props.children}
            </Link>
          )}
        >
          <Frame
            topBar={topBar}
            navigation={navigation}
            showMobileNavigation={mobileNavigationActive}
            onNavigationDismiss={toggleMobileNavigationActive}
          >
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner accessibilityLabel="Loading app" size="large" />
              </div>
            ) : (
              <>
                <Routes />
              </>
            )}
          </Frame>
        </AppProvider>
      </DataProvider>
    </BrowserRouter>
  );
}

export default App;

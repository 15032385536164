import { Fragment, useEffect, useState } from 'react';
import { Country } from '../api/interfaces/country';
import { UIProduct } from '../views/Products';
import { useAuth, useUpdates } from '../context/DataContext';
import { TextField } from '@shopify/polaris';

type MetafieldCountries = {
  [c: string]: {
    price: string;
    priceOriginal: string;
    currency: string;
    currencyOriginal: string;
  };
};

type MetafieldMapData = {
  [k: string]: {
    variantId: string;
    namespace: 'multiprice_app';
    countries: MetafieldCountries;
  };
};

const CountryColumns = ({
  products,
  countries,
}: {
  products: UIProduct[];
  countries: Country[];
}) => {
  const { authToken: token } = useAuth();
  const { updates, setUpdates } = useUpdates();
  const [metafieldMap, setMetafieldMap] = useState<MetafieldMapData>({});
  const defaultCurrency = token
    ? JSON.parse(atob(token.split('.')[1])).currency
    : 'EUR';

  const handleChange = (
    field: string,
    value: string,
    id: string,
    country: string
  ) => {
    const copy = JSON.parse(JSON.stringify(metafieldMap));
    copy[id].countries[country][field] = value;

    const hasKey = !!updates[id];
    const hasChanges = updates[id] && updates[id].includes(country);

    if ((copy[id].countries[country][`${field}Original`] || '') !== value) {
      if (!hasChanges) {
        setUpdates({
          ...updates,
          [id]: hasKey
            ? [
                ...updates[id].filter((c) => !c.includes(country)),
                `${country}|${value}|${
                  copy[id].countries[country][`${field}Original`] || ''
                }`,
              ]
            : [
                `${country}|${value}|${
                  copy[id].countries[country][`${field}Original`] || ''
                }`,
              ],
        });
      }
    } else {
      setUpdates({
        ...updates,
        [id]: [...updates[id].filter((c) => !c.includes(country))],
      });
    }
    setMetafieldMap(copy);
  };

  useEffect(() => {
    if (products.length && !Object.keys(metafieldMap).length) {
      setMetafieldMap(
        products.reduce((acc, curr) => {
          const multipriceAppMetafield = curr.metafield;
          let c: { [k: string]: object };
          if (multipriceAppMetafield) {
            const json: MetafieldCountries = JSON.parse(
              multipriceAppMetafield.value
            );

            // TODO: Merge countries with metafield country keys
            c = Object.keys(json).reduce(
              (acc, curr) => ({
                ...acc,
                [curr]: {
                  ...json[curr],
                  priceOriginal: json[curr].price,
                  currencyOriginal: json[curr].currency,
                },
              }),
              {}
            );

            const countryKeys = countries.map((c) => c.identifier);
            countryKeys.forEach((k) => {
              if (!c[k]) {
                c[k] = {
                  price: '',
                  originalPrice: '',
                  currency: defaultCurrency,
                  originalCurrency: defaultCurrency,
                };
              }
            });
          } else {
            c = countries.reduce(
              (acc, curr) => ({
                ...acc,
                [curr.identifier]: {
                  price: '',
                  originalPrice: '',
                  currency: defaultCurrency,
                  originalCurrency: defaultCurrency,
                },
              }),
              {}
            );
          }

          return {
            ...acc,
            [curr.id]: {
              variantId: curr.variantId,
              namespace: 'multiprice_app',
              countries: c,
            },
          };
        }, {})
      );
    }
  }, [products, countries, metafieldMap, defaultCurrency]);

  const getProductCountryData = (
    id: string,
    country: string,
    currency: string
  ) => {
    const data = metafieldMap[id].countries[country];

    return (
      <div
        style={{
          display: 'flex',
          height: 50,
          borderBottom: 'solid 1px #eee',
          padding: 8,
        }}
      >
        <div style={{ width: 72, marginRight: 8 }}>
          <TextField
            label=""
            value={(data && data.price) || ''}
            onChange={(value) => handleChange('price', value, id, country)}
          />
        </div>
        <span style={{ alignSelf: 'center' }}>{currency}</span>
      </div>
    );
  };

  if (!products.length || !Object.keys(metafieldMap).length) return null;
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        display: 'flex',
      }}
    >
      {/* Country Columns */}
      {countries.map((country) => (
        <div
          key={country.identifier}
          style={{
            minWidth: 150,
            display: 'flex',
            flexFlow: 'column',
            borderRight: 'solid 1px #eee',
          }}
        >
          {/* Heading */}
          <div
            style={{
              borderBottom: 'solid 1px #eee',
              height: 50,
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              padding: 8,
              background: '#fff',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          >
            {country.identifier.toUpperCase()}
          </div>
          {products.map((p) => (
            <Fragment key={`${country.identifier}-${p.id}`}>
              {getProductCountryData(
                p.id,
                country.identifier,
                country.currency
              )}
            </Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CountryColumns;

export type QueryParams = {
  [key: string]: string;
};

export const getQueryParams = (): QueryParams => {
  const search = window.location.search.split('&');
  return search.reduce((prev, next) => {
    const keyValue = next.split('=');
    return { ...prev, [keyValue[0].replace('?', '')]: keyValue[1] };
  }, {});
};

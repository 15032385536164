import React, {
  useState,
  ReactElement,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
} from 'react';
import { Country } from '../api/interfaces/country';
import { Product } from '../api/interfaces/product';

type DataProviderType = {
  children: ReactElement;
  token: string;
  shop: string;
};

export type Update = {
  [id: string]: string[];
};

type DataContextType = {
  updates: Update;
  setUpdates: Dispatch<SetStateAction<Update>>;
  products: Product[];
  setProducts: Dispatch<SetStateAction<Product[]>>;
  countries: Country[];
  setCountries: Dispatch<SetStateAction<Country[]>>;
  authToken: string;
  setAuthToken: Dispatch<SetStateAction<string>>;
  authShop: string;
  setAuthShop: Dispatch<SetStateAction<string>>;
};

const ctxDefaultValue: DataContextType = {
  products: [],
  setProducts: () => {},
  updates: {},
  setUpdates: () => {},
  countries: [],
  setCountries: () => {},
  authToken: '',
  setAuthToken: () => {},
  authShop: '',
  setAuthShop: () => {},
};

const DataContext = createContext(ctxDefaultValue);

export const useUpdates = () => {
  const { updates, setUpdates } = useContext(DataContext);

  return { updates, setUpdates };
};

export const useProducts = () => {
  const { products, setProducts } = useContext(DataContext);

  return { products, setProducts };
};

export const useCountries = () => {
  const { countries, setCountries } = useContext(DataContext);

  return { countries, setCountries };
};

export const useAuth = () => {
  const { authToken, authShop } = useContext(DataContext);

  return { authToken, authShop };
};

const DataProvider = ({ token, shop, children }: DataProviderType) => {
  const [updates, setUpdates] = useState<Update>(ctxDefaultValue.updates);
  const [products, setProducts] = useState<Product[]>(ctxDefaultValue.products);
  const [countries, setCountries] = useState<Country[]>(
    ctxDefaultValue.countries
  );
  const [authToken, setAuthToken] = useState(ctxDefaultValue.authToken);
  const [authShop, setAuthShop] = useState(ctxDefaultValue.authShop);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
    }

    if (shop) {
      setAuthShop(shop);
    }
  }, [token, shop]);

  return (
    <DataContext.Provider
      value={{
        updates,
        setUpdates,
        products,
        setProducts,
        countries,
        setCountries,
        authToken,
        setAuthToken,
        authShop,
        setAuthShop,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;

import { QueryParams } from '../utils';
import { Country } from './interfaces/country';
import { Product } from './interfaces/product';

export const authenticate = async (payload: QueryParams) => {
  const url = `${process.env.REACT_APP_API_HOST}auth`;
  const res = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  const data = await res.json();

  if (data.authUrl) window.location.href = data.authUrl;
  return data;
};

export const install = async (payload: QueryParams) => {
  const url = `${process.env.REACT_APP_API_HOST}install`;
  const res = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (res.status === 200) return;
};

export const getProducts = async (token: string): Promise<Product[]> => {
  const url = `${process.env.REACT_APP_API_HOST}products`;
  const res = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (res.status === 200) {
    const data = await res.json();
    return data;
  }

  return [];
};

export const getCountries = async (token: string): Promise<Country[]> => {
  const url = `${process.env.REACT_APP_API_HOST}countries`;
  const res = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (res.status === 200) {
    const data = await res.json();
    return data;
  }

  return [];
};

export const deleteCountry = async (country: Country, token: string) => {
  const url = `${process.env.REACT_APP_API_HOST}countries/${country.id}?identifier=${country.identifier}`;
  const res = await fetch(url, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (res.status === 200) {
    return true;
  }

  return false;
};

export const createCountry = async (
  name: string,
  currency: string,
  token: string
): Promise<Country | null> => {
  const url = `${process.env.REACT_APP_API_HOST}countries`;
  const res = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ name, currency }),
  });

  if (res.status === 200) {
    const data = await res.json();
    return data.country;
  }
  return null;
};

export const updateProductsMetafieldDatas = async (
  metafields: object,
  token: string
) => {
  const url = `${process.env.REACT_APP_API_HOST}metafields`;
  const res = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ metafields }),
  });

  if (res.status === 200) {
    return 'ok';
  }
  return null;
};

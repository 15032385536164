import classes from './ProductColumn.module.css';
import { UIProduct } from '../views/Products';

const ProductColumn = ({ products }: { products: UIProduct[] }) => {
  return (
    <div className={classes.columnContainer}>
      {/* Heading */}
      <div className={classes.staticHeading}>
        <div className={classes.headingTitle}>Product</div>
        {products.map((product) => (
          <div key={`product-${product.id}`} className={classes.productWrapper}>
            <div
              className={classes.productImage}
              style={{
                backgroundImage: product.image ? `url("${product.image}")` : '',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
            ></div>
            <span className={classes.product}>{product.title}</span>
          </div>
        ))}
      </div>
      <div className={classes.priceWrapper}>
        <div className={classes.headingTitle}>Price</div>
        {products.map((product) => (
          <div key={`price-${product.id}`} className={classes.price}>
            {product.price} €
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductColumn;

import { FormEvent, useState } from 'react';
import { useAuth, useCountries } from '../context/DataContext';
import { createCountry, deleteCountry } from '../api';
import {
  Card,
  Page,
  Layout,
  ResourceItem,
  ResourceList,
  TextStyle,
  Button,
  Modal,
  TextContainer,
  Form,
  TextField,
} from '@shopify/polaris';
import { Country } from '../api/interfaces/country';

type AddItem = {
  type: string;
  name: string;
  currency: string;
  moneyFormat?: string;
};

const Settings = () => {
  const { countries, setCountries } = useCountries();

  const { authToken: token } = useAuth();
  const [removableCountry, setRemovableCountry] = useState<Country | null>(
    null
  );

  const defaultCurrency = token
    ? JSON.parse(atob(token.split('.')[1])).currency
    : 'EUR';
  const defaultAddItem = {
    type: 'country',
    name: '',
    currency: defaultCurrency,
  };

  const [addItem, setAddItem] = useState<AddItem | null>(null);

  const handleCloseDeleteModal = async (answer: boolean) => {
    if (!answer) {
      setRemovableCountry(null);
      return;
    }

    await deleteCountry(removableCountry!, token);
    setCountries([
      ...countries.filter((c) => c.identifier !== removableCountry!.identifier),
    ]);
    setRemovableCountry(null);
  };

  const handleCreateItem = async (evt: null | FormEvent<HTMLFormElement>) => {
    evt && evt.preventDefault();
    if (!addItem?.name.length) return;

    const country = await createCountry(addItem.name, addItem.currency, token);
    if (country) setCountries([...countries, country]);

    setAddItem(null);
  };

  return (
    <Page fullWidth title="Settings">
      <Layout>
        <Layout.Section>
          <Card title="Countries" sectioned>
            <Card.Section>
              <ResourceList
                emptyState={<p>No countries yet.</p>}
                items={countries}
                renderItem={(item: Country) => {
                  const { id, identifier, currency } = item;
                  return (
                    <ResourceItem id={id} onClick={() => null}>
                      <h3 style={{ display: 'flex' }}>
                        <span
                          style={{
                            flex: 1,
                            display: 'flex',
                            flexFlow: 'column',
                          }}
                        >
                          <TextStyle variation="strong">
                            {identifier.toUpperCase()}
                          </TextStyle>
                          <TextStyle>
                            currency: {currency.toUpperCase()}
                          </TextStyle>
                        </span>
                        <Button
                          plain
                          destructive
                          onClick={() => setRemovableCountry(item)}
                        >
                          Remove
                        </Button>
                      </h3>
                    </ResourceItem>
                  );
                }}
              />
            </Card.Section>
            <Card.Section>
              <Button
                primary
                fullWidth
                onClick={() => setAddItem(defaultAddItem)}
              >
                Add new country
              </Button>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
      <Modal
        open={!!removableCountry}
        onClose={() => handleCloseDeleteModal(false)}
        title="Really delete?"
        primaryAction={{
          content: 'Delete',
          destructive: true,
          onAction: () => handleCloseDeleteModal(true),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => handleCloseDeleteModal(false),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              If you remove this country, it will also be removed from all
              products currently using it.
            </p>
            <p>Really proceed with removal?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>

      <Modal
        open={!!addItem}
        onClose={() => setAddItem(null)}
        title={`Add a ${addItem && addItem.type ? addItem.type : ''}`}
        primaryAction={{
          content: 'Add',
          disabled: !addItem?.name.length || !addItem?.currency.length,
          onAction: () => handleCreateItem(null),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setAddItem(null),
          },
        ]}
      >
        <Modal.Section>
          <Form onSubmit={(evt) => handleCreateItem(evt)}>
            <TextField
              label={addItem?.type}
              value={addItem?.name}
              onChange={(name) => setAddItem({ ...addItem!, name })}
            />
            <TextField
              label="currency"
              value={addItem?.currency}
              onChange={(currency) => setAddItem({ ...addItem!, currency })}
            />
          </Form>
        </Modal.Section>
      </Modal>
    </Page>
  );
};

export default Settings;

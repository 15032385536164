import { Switch, Route, Redirect } from 'react-router-dom';
import Products from './views/Products';
import Settings from './views/Settings';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Products />
      </Route>
      <Route path="/settings" component={Settings} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;

import React, { useEffect, useState, useCallback } from 'react';
import {
  Page,
  ContextualSaveBar,
  DisplayText,
  SkeletonPage,
  Card,
  Layout,
  TextContainer,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris';
import ProductColumn from '../components/ProductColumn';
import CountryColumns from '../components/CountryColumns';
import {
  useAuth,
  useCountries,
  useProducts,
  useUpdates,
} from '../context/DataContext';
import {
  getCountries,
  getProducts,
  updateProductsMetafieldDatas,
} from '../api';
import { Metafield } from '../api/interfaces/product';
import { Link } from 'react-router-dom';

export type UIProduct = {
  id: string;
  title: string;
  metafield: Metafield;
  image: string | null;
  price: string;
  variantId: string;
};

const Products = () => {
  const { updates, setUpdates } = useUpdates();
  const { products, setProducts } = useProducts();
  const { countries, setCountries } = useCountries();
  const [loading, setLoading] = useState(true);
  const { authToken: token, authShop: shop } = useAuth();
  const [localProducts, setLocalProducts] = useState<UIProduct[]>([]);

  const defaultCurrency = token
    ? JSON.parse(atob(token.split('.')[1])).currency
    : 'EUR';

  const fetchData = useCallback(async () => {
    if (token) {
      const res = await Promise.all([
        await getProducts(token),
        await getCountries(token),
      ]);
      setProducts(res[0]);
      setCountries(res[1]);
      setLoading(false);
    }
  }, [setProducts, setCountries, token]);

  // on mount, set updates to none
  useEffect(() => {
    setUpdates({});
  }, [setUpdates]);

  useEffect(() => {
    if (
      token &&
      shop &&
      (!localProducts.length || localProducts.length !== products.length)
    ) {
      if (!products.length && loading) {
        fetchData();
      } else if (!localProducts.length) {
        const uiProducts: UIProduct[] = products.reduce((acc, p) => {
          if (p.variants[0].node.title !== 'Default Title') {
            return [
              ...acc,
              ...p.variants.map((v) => ({
                id: v.node.id,
                title: `${p.node.title} - ${v.node.title}`,
                metafield: v.node.metafield,
                image: v.image || p.image,
                price: v.node.price,
                variantId: v.node.id,
              })),
            ];
          }

          return [
            ...acc,
            {
              id: p.node.id,
              title: p.node.title,
              metafield: p.node.metafield,
              image: p.image,
              price: p.variants[0].node.price,
              variantId: p.variants[0].node.id,
            },
          ];
        }, [] as UIProduct[]);
        setLocalProducts(uiProducts);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [loading, localProducts, products, fetchData, token, shop]);

  const handleSaveProductChanges = async () => {
    const payload = Object.keys(updates)
      .map((key) => {
        // Metafield Id from product
        const localProduct = localProducts.find((lp) => lp.id === key);
        const metafieldId =
          localProduct && localProduct.metafield
            ? localProduct.metafield.id
            : null;
        const existingMetadata =
          localProduct &&
          localProduct.metafield &&
          localProduct.metafield.value &&
          localProduct.metafield.value.length
            ? JSON.parse(localProduct.metafield.value)
            : {};
        // product has no updates, return empty element which we filter out at the end
        if (!updates[key].length) return false;
        const value = updates[key].reduce((acc, curr) => {
          const values = curr.split('|');
          // TODO: Value validation for number
          if (!values[1] && values[2]) return acc;

          const country = countries.find(
            (c) => c.identifier.toUpperCase() === values[0]
          );
          return {
            ...acc,
            [values[0]]: {
              price: values[1].replace(/,/g, '.'),
              currency: country?.currency || defaultCurrency,
              moneyFormat: '',
            },
          };
        }, existingMetadata);

        const metafield: { [key: string]: string } = {
          namespace: 'multiprice_app',
          key: 'multiprice_app',
          value: JSON.stringify(value),
          valueType: 'JSON_STRING',
        };

        if (metafieldId) {
          metafield.id = metafieldId;
        }

        return {
          input: {
            id: key,
            metafields: [metafield],
          },
        };
        // Filter empty values out
      })
      .filter((v) => v);

    await updateProductsMetafieldDatas(payload, token);
    // Fetch data again
    setUpdates({});
    setProducts([]);
    setLoading(true);
    fetchData();
  };

  const hasUpdates =
    Object.keys(updates).length &&
    Object.keys(updates).reduce(
      (acc, curr) => (!acc ? !!updates[curr].length : acc),
      false
    );

  const contextualSaveBarMarkup = hasUpdates ? (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSaveProductChanges,
      }}
    />
  ) : null;

  if (loading || !localProducts.length)
    return (
      <SkeletonPage fullWidth>
        <Card sectioned>
          <Layout>
            <Layout.Section oneThird>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Layout.Section>
            <Layout.Section oneThird>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Layout.Section>
            <Layout.Section oneThird>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Layout.Section>
          </Layout>
        </Card>

        <Card sectioned>
          <Layout>
            <Layout.Section oneThird>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Layout.Section>
            <Layout.Section oneThird>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Layout.Section>
            <Layout.Section oneThird>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Layout.Section>
          </Layout>
        </Card>
      </SkeletonPage>
    );

  return (
    <>
      {contextualSaveBarMarkup}
      <Page fullWidth title="Products">
        <div
          className="Polaris-Card"
          style={{
            display: 'flex',
          }}
        >
          <ProductColumn products={localProducts} />
          {!countries.length ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                padding: '4rem 2rem',
              }}
            >
              <DisplayText size="small">
                You have not set countries yet. Add countries from
                <span style={{ marginLeft: 8 }}>
                  <Link to="/settings">Settings</Link>
                </span>
              </DisplayText>
            </div>
          ) : (
            <CountryColumns products={localProducts} countries={countries} />
          )}
        </div>
      </Page>
    </>
  );
};

export default Products;
